import type { CmsConfig } from 'netlify-cms-core'

import {
  IS_DEVELOPMENT,
  GATSBY_NETLIFY_CMS_BACKEND_NAME,
  GATSBY_NETLIFY_CMS_BACKEND_BRANCH,
  GATSBY_NETLIFY_CMS_BACKEND_API_ROOT,
  GATSBY_NETLIFY_CMS_BACKEND_REPO_NAME,
  GATSBY_NETFLIY_CMS_BACKEND_APP_ID,
  GATSBY_NETLIFY_CMS_BACKEND_AUTH_ENDPOINT,
  GATSBY_NETLIFY_CMS_BACKEND_AUTH_TYPE,
  GATSBY_NETLIFY_CMS_BACKEND_BASE_URL,
} from '../../../../config/env'
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../../../../config/i18n'
import { COLLECTIONS } from './collections'

export const config: CmsConfig = {
  backend: {
    name: GATSBY_NETLIFY_CMS_BACKEND_NAME as any,
    branch: GATSBY_NETLIFY_CMS_BACKEND_BRANCH,
    api_root: GATSBY_NETLIFY_CMS_BACKEND_API_ROOT,
    repo: GATSBY_NETLIFY_CMS_BACKEND_REPO_NAME,
    app_id: GATSBY_NETFLIY_CMS_BACKEND_APP_ID,
    base_url: GATSBY_NETLIFY_CMS_BACKEND_BASE_URL,
    auth_endpoint: GATSBY_NETLIFY_CMS_BACKEND_AUTH_ENDPOINT,
    auth_type: GATSBY_NETLIFY_CMS_BACKEND_AUTH_TYPE,
  },
  media_folder: 'uploads/media',
  local_backend: IS_DEVELOPMENT,
  collections: COLLECTIONS,
  i18n: {
    structure: 'single_file',
    locales: AVAILABLE_LANGUAGES,
    default_locale: DEFAULT_LANGUAGE,
  },
}
