import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const CASE_STUDIES_PAGE: CmsCollectionFile = {
  label: 'Case Studies Page',
  name: PAGE_NAME.CaseStudies,
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.Pages, `${PAGE_NAME.CaseStudies}.json`),
  fields: [
    {
      label: 'Landing section',
      name: 'landingSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Strings',
      name: 'strings',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'List item CTA Button',
          name: 'ctaButton',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Products label',
          name: 'productsLabel',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'All products filter label',
          name: 'allProductsLabel',
          widget: 'string',
          i18n: true,
        },
      ],
    },
    createSeoField(),
  ],
}
