import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const PRIVACY_POLICY_PAGE: CmsCollectionFile = {
  label: 'Privacy Policy Page',
  name: PAGE_NAME.PrivacyPolicy,
  i18n: true,
  file: makeContentPath(
    COLLECTION_NAME.Pages,
    `${PAGE_NAME.PrivacyPolicy}.json`
  ),
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Policy Content section',
      name: 'policyContentSection',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'markdown',
          i18n: true,
        },
      ],
    },
    createSeoField(),
  ],
}
