import type { CmsField } from 'netlify-cms-core'

const PATH_REGEX = /^(([a-zA-Z-]+?\/)+)?([a-zA-Z-]+)(\.([a-zA-Z-]+))?$/
const DASH_CASE_REGEX = /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/

export const regexToPattern = (regex: RegExp) => {
  const str = String(regex)

  return str.slice(1, str.length - 1)
}

export const makeContentPath = (
  collectionName: cms.CollectionName,
  fileName?: string
) => {
  const path = ['uploads/content', collectionName, fileName]
    .filter(Boolean)
    .join('/')

  if (!PATH_REGEX.test(path)) {
    throw new Error('Invalid content path.')
  }

  return path
}

export const createIdentifierField = (): CmsField => ({
  label: 'Identifier',
  name: 'identifier',
  widget: 'string',
  pattern: [
    regexToPattern(DASH_CASE_REGEX),
    'The identifier must be dash-case',
  ],
})

export const createSeoField = (isDynamic = false): CmsField => {
  const hint = isDynamic
    ? {
        hint: `You can use templating with "{}" to extract entity properties. For example: "{entity.name}".`,
      }
    : undefined

  return {
    label: 'SEO',
    name: 'seo',
    widget: 'object',
    i18n: true,
    required: true,
    fields: [
      {
        label: 'Title',
        name: 'title',
        widget: 'string',
        required: true,
        i18n: true,
        ...hint,
      },
      {
        label: 'Meta',
        name: 'meta',
        widget: 'object',
        required: true,
        i18n: true,
        fields: [
          {
            label: 'Description',
            name: 'description',
            widget: 'string',
            required: true,
            i18n: true,
            ...hint,
          },
          {
            label: 'Keywords',
            name: 'keywords',
            widget: 'list',
            allow_add: true,
            required: false,
            i18n: true,
            hint: `Keywords must be comma-separated. For example: "keyword1, keyword2". ${
              hint ? hint?.hint : ''
            }`,
          },
        ],
      },
      {
        label: 'Open Graph tags',
        name: 'openGraph',
        widget: 'object',
        required: false,
        i18n: true,
        fields: [
          {
            label: 'Title',
            name: 'title',
            widget: 'string',
            required: false,
            i18n: true,
            ...hint,
          },
          {
            label: 'Type',
            name: 'type',
            widget: 'string',
            required: false,
            i18n: true,
          },
          {
            label: 'Description',
            name: 'description',
            widget: 'string',
            required: false,
            i18n: true,
            ...hint,
          },
          {
            label: 'Image',
            name: 'image',
            widget: 'image',
            // @ts-ignore
            choose_url: false,
            required: false,
            i18n: true,
          },
          {
            label: 'Locale',
            name: 'locale',
            widget: 'string',
            required: false,
            i18n: true,
          },
        ],
      },
    ],
  }
}
