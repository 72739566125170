import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const ABOUT_PAGE: CmsCollectionFile = {
  label: 'About Page',
  name: PAGE_NAME.About,
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.Pages, `${PAGE_NAME.About}.json`),
  fields: [
    {
      label: 'Landing section',
      name: 'landingSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Company history section',
      name: 'companyHistorySection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Paragraph',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Company description section',
      name: 'companyDescriptionSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Carousel',
      label_singular: 'item',
      widget: 'list',
      name: 'carousel',
      fields: [
        {
          label: 'Image',
          name: 'image',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
          required: false,
        },
        {
          label: 'YouTube link',
          name: 'youtube',
          widget: 'string',
          required: false,
        },
      ],
    },
    createSeoField(),
  ],
}
