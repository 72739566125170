import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const SOFTWARE_PAGE: CmsCollectionFile = {
  label: 'Software Page',
  name: PAGE_NAME.Software,
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.Pages, `${PAGE_NAME.Software}.json`),
  fields: [
    {
      label: 'Landing section',
      name: 'landingSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Strings',
      name: 'strings',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Tags label',
          name: 'tagsLabel',
          widget: 'string',
          i18n: true,
        },
      ],
    },
    createSeoField(),
  ],
}
