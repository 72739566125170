export const COLLECTION_NAME = {
  Pages: 'pages' as const,
  Products: 'products' as const,
  ProductsCategories: 'products-categories' as const,
  Tags: 'tags' as const,
  Software: 'software' as const,
  CommonElements: 'common-elements' as const,
  CaseStudies: 'case-studies' as const,
  Config: 'config' as const,
}

export const PAGE_NAME = {
  Home: 'home' as const,
  Products: 'products' as const,
  Software: 'software' as const,
  CaseStudies: 'case-studies' as const,
  CaseStudy: 'case-study' as const,
  About: 'about' as const,
  Contact: 'contact' as const,
  ProductDetails: 'product-details' as const,
  SoftwareDetails: 'software-details' as const,
  PrivacyPolicy: 'privacy-policy' as const,
}

export const INTERNAL_PAGES_NAMES: Partial<Record<cms.PageName, string>> = {
  [PAGE_NAME.Home]: 'Home',
  [PAGE_NAME.Products]: 'Products',
  [PAGE_NAME.Software]: 'Software',
  [PAGE_NAME.CaseStudies]: 'Case Studies',
  [PAGE_NAME.About]: 'About',
  [PAGE_NAME.Contact]: 'Contact',
  [PAGE_NAME.ProductDetails]: 'Product Details',
  [PAGE_NAME.SoftwareDetails]: 'Software Details',
}
