import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME } from '../../../../../config/cms'
import { makeContentPath } from './utils'

export const FOOTER: CmsCollectionFile = {
  label: 'Footer',
  name: 'footer',
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.CommonElements, `footer.json`),
  fields: [
    {
      label: 'Logo',
      name: 'logo',
      widget: 'image',
      // @ts-ignore
      choose_url: false,
    },
    {
      label: 'Address',
      name: 'address',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Company registry details',
      name: 'companyRegistryDetails',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Registry detail',
          name: 'registryDetail',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Info/contact',
      name: 'infoContact',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'label',
          name: 'label',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'medium',
          name: 'medium',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Socials',
      name: 'socials',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Title',
          name: 'title',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Link',
          name: 'link',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Designed by',
      name: 'designedBy',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Rights reserved',
      name: 'rightsReserved',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Terms and privacy',
      name: 'termsAndPrivacy',
      widget: 'text',
      i18n: true,
    },
  ],
}
