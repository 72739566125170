import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const PRODUCT_DETAILS_PAGE: CmsCollectionFile = {
  label: 'Product Details Page',
  name: PAGE_NAME.ProductDetails,
  i18n: true,
  file: makeContentPath(
    COLLECTION_NAME.Pages,
    `${PAGE_NAME.ProductDetails}.json`
  ),
  fields: [
    {
      label: 'General section',
      name: 'generalSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Compability',
          name: 'compability',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Description section',
      name: 'descriptionSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'More information',
          name: 'moreInformation',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Benefits section',
      name: 'benefitsSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Benefits',
          name: 'benefits',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Specification section',
      name: 'specificationSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Additional equipment',
          name: 'additionalEquipment',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    createSeoField(true),
  ],
}
