import type { CmsCollection } from 'netlify-cms-core'

import { COLLECTION_NAME } from '../../../../../../config/cms'
import { ABOUT_PAGE } from './about'
import { CASE_STUDIES_PAGE } from './case-studies'
import { CASE_STUDY_PAGE } from './case-study'
import { CONTACT_PAGE } from './contact'
import { HOME_PAGE } from './home'
import { PRIVACY_POLICY_PAGE } from './privacy-policy'
import { PRODUCT_DETAILS_PAGE } from './product-details'
import { PRODUCTS_PAGE } from './products'
import { SOFTWARE_PAGE } from './software'
import { SOFTWARE_DETAILS_PAGE } from './software-details'

export const PAGES_COLLECTION: CmsCollection = {
  label: 'Pages',
  label_singular: 'Page',
  name: COLLECTION_NAME.Pages,
  format: 'json',
  i18n: true,
  files: [
    ABOUT_PAGE,
    CASE_STUDIES_PAGE,
    CASE_STUDY_PAGE,
    CONTACT_PAGE,
    HOME_PAGE,
    PRIVACY_POLICY_PAGE,
    PRODUCT_DETAILS_PAGE,
    PRODUCTS_PAGE,
    SOFTWARE_DETAILS_PAGE,
    SOFTWARE_PAGE,
  ],
}
