import type { CmsCollection } from 'netlify-cms-core'

import { COLLECTION_NAME } from '../../../../../config/cms'
import { createIdentifierField, makeContentPath } from './utils'

export const PRODUCTS_COLLECTION: CmsCollection = {
  label: 'Products',
  label_singular: 'Product',
  name: COLLECTION_NAME.Products,
  folder: makeContentPath(COLLECTION_NAME.Products),
  create: true,
  format: 'json',
  i18n: true,
  identifier_field: 'identifier',
  summary: '{{fields.name}}',
  fields: [
    createIdentifierField(),
    {
      label: 'Name',
      name: 'name',
      widget: 'string',
      i18n: true,
    },
    {
      label: 'Preview tile',
      name: 'preview',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Image',
          name: 'image',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Description',
          name: 'description',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Order',
      name: 'order',
      widget: 'number',
    },
    {
      label: 'Details Page',
      name: 'page',
      widget: 'object',
      fields: [
        {
          label: 'Carousel',
          label_singular: 'item',
          widget: 'list',
          name: 'items',
          fields: [
            {
              label: 'Image',
              name: 'image',
              widget: 'image',
              // @ts-ignore
              choose_url: false,
              required: false,
            },
            {
              label: 'YouTube link',
              name: 'youtube',
              widget: 'string',
              required: false,
            },
          ],
        },
      ],
    },
    {
      label: 'Media',
      name: 'media',
      widget: 'object',
      fields: [
        {
          label: 'Carousel',
          label_singular: 'item',
          widget: 'list',
          name: 'items',
          fields: [
            {
              label: 'Image',
              name: 'image',
              widget: 'image',
              // @ts-ignore
              choose_url: false,
              required: false,
            },
            {
              label: 'YouTube link',
              name: 'youtube',
              widget: 'string',
              required: false,
            },
          ],
        },
      ],
    },
    {
      label: 'Downloadable Files',
      name: 'files',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Displayed label',
          name: 'displayedLabel',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'File name',
          name: 'fileName',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'File',
          name: 'file',
          widget: 'file',
          i18n: true,
          // @ts-ignore
          choose_url: false,
        },
      ],
    },
    {
      label: 'Category',
      name: 'category',
      widget: 'relation',
      collection: COLLECTION_NAME.ProductsCategories,
      multiple: false,
      search_fields: ['title'],
      value_field: 'identifier',
      display_fields: ['title'],
    },
    {
      label: 'Summary heading',
      name: 'heading',
      widget: 'string',
      i18n: true,
    },
    {
      label: 'Summary description',
      name: 'description',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Alternative description summary',
      name: 'altDescriptionSummary',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Alternative description',
      name: 'altDescription',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Tags',
      name: 'tags',
      widget: 'relation',
      collection: COLLECTION_NAME.Tags,
      multiple: true,
      search_fields: ['title'],
      value_field: 'identifier',
      display_fields: ['title'],
      required: false,
    },
    {
      label: 'Additional equipment',
      name: 'additionalEq',
      widget: 'relation',
      collection: COLLECTION_NAME.Products,
      multiple: true,
      search_fields: ['name'],
      value_field: 'identifier',
      display_fields: ['name'],
      required: false,
    },
    {
      label: 'Features',
      label_singular: 'feature',
      name: 'features',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Image',
          name: 'image',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Top',
          name: 'top',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Bottom',
          name: 'bottom',
          widget: 'string',
          i18n: true,
        },
      ],
    },
    {
      label: 'Benefits',
      name: 'benefits',
      widget: 'list',
      i18n: true,
      field: { label: 'Name', name: 'name', widget: 'string', i18n: true },
    },
  ],
}
