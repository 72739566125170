import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const CASE_STUDY_PAGE: CmsCollectionFile = {
  label: 'Case Study Page',
  name: PAGE_NAME.CaseStudy,
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.Pages, `${PAGE_NAME.CaseStudy}.json`),
  fields: [
    {
      label: 'Landing section',
      name: 'landingSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Products',
          name: 'products',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Location',
          name: 'location',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    createSeoField(true),
  ],
}
