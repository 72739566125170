import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const CONTACT_PAGE: CmsCollectionFile = {
  label: 'Contact Page',
  name: PAGE_NAME.Contact,
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.Pages, `${PAGE_NAME.Contact}.json`),
  fields: [
    {
      label: 'Landing section',
      name: 'landingSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Members section',
      name: 'membersSection',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Image',
          name: 'image',
          widget: 'image',
          i18n: false,
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Full Name',
          name: 'fullName',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Position',
          name: 'position',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Email',
          name: 'email',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Contact section',
      name: 'contactSection',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Email',
          name: 'email',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    createSeoField(),
  ],
}
