import type { CmsCollection } from 'netlify-cms-core'

import { COLLECTION_NAME } from '../../../../../config/cms'
import { createIdentifierField, makeContentPath } from './utils'

export const PRODUCTS_CATEGORIES_COLLECTION: CmsCollection = {
  label: 'Products Categories',
  label_singular: 'Products Category',
  name: COLLECTION_NAME.ProductsCategories,
  folder: makeContentPath(COLLECTION_NAME.ProductsCategories),
  create: true,
  format: 'json',
  i18n: true,
  identifier_field: 'identifier',
  summary: '{{fields.title}}',
  fields: [
    createIdentifierField(),
    {
      label: 'Title',
      name: 'title',
      widget: 'string',
      i18n: true,
    },
  ],
}
