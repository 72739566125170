const Nothing = (() => {
  const mock = () => Nothing
  mock.toString = mock.toLocaleString = mock[Symbol.toPrimitive] = () => ''
  mock.valueOf = () => false

  return new Proxy(Object.freeze(mock), {
    // eslint-disable-next-line no-prototype-builtins
    get: (target, key) => (target.hasOwnProperty(key) ? target[key] : Nothing),
  })
})()

export const win: typeof window =
  typeof window !== 'undefined' ? window : Nothing

export const doc: typeof document =
  typeof document !== 'undefined' ? document : Nothing
