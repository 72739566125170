import type { CmsCollectionFile } from 'netlify-cms-core'

import { COLLECTION_NAME, PAGE_NAME } from '../../../../../../config/cms'
import { createSeoField, makeContentPath } from '../utils'

export const HOME_PAGE: CmsCollectionFile = {
  label: 'Home Page',
  name: PAGE_NAME.Home,
  i18n: true,
  file: makeContentPath(COLLECTION_NAME.Pages, `${PAGE_NAME.Home}.json`),
  fields: [
    {
      label: 'Landing section',
      name: 'landingSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Caption',
          name: 'caption',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    {
      label: 'Case study section',
      name: 'caseStudySection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Label',
          name: 'label',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Description',
          name: 'description',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Button label',
          name: 'buttonLabel',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Image',
          name: 'image',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
      ],
    },
    {
      label: 'Products section',
      name: 'productsSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Label',
          name: 'label',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Description',
          name: 'description',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Featured products',
          name: 'featuredProducts',
          widget: 'relation',
          i18n: false,
          collection: COLLECTION_NAME.Products,
          multiple: true,
          search_fields: ['name'],
          value_field: 'identifier',
          display_fields: ['name'],
          required: false,
        },
      ],
    },
    {
      label: 'Software section',
      name: 'softwareSection',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Label',
          name: 'label',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Heading',
          name: 'heading',
          widget: 'text',
          i18n: true,
        },
      ],
    },
    createSeoField(),
  ],
}
