import type React from 'react'
import { useEffect } from 'react'

import { doc } from '../../utils/monads'
import { config } from './config'

const CmsAdminPage: React.FC = () => {
  useEffect(() => {
    const loadCMS = async () => {
      if (doc.readyState == 'complete') {
        const { default: CMS } = await import('netlify-cms-app')
        CMS.init({ config })
      }
    }

    if (doc.readyState === 'complete') {
      loadCMS()
    } else {
      doc.addEventListener('readystatechange', loadCMS)
      return () => doc.removeEventListener('readystatechange', loadCMS)
    }
  }, [])
  return null
}

export default CmsAdminPage
