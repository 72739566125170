import type { CmsCollection } from 'netlify-cms-core'

import { CASE_STUDIES_COLLECTION } from './case-studies'
import { COMMON_ELEMENTS_COLLECTION } from './common-elements'
import { PAGES_COLLECTION } from './pages'
import { PRODUCTS_COLLECTION } from './products'
import { PRODUCTS_CATEGORIES_COLLECTION } from './products-categories'
import { SOFTWARE_COLLECTION } from './software'
import { TAGS_COLLECTION } from './tags'

export const COLLECTIONS: CmsCollection[] = [
  PAGES_COLLECTION,
  COMMON_ELEMENTS_COLLECTION,
  TAGS_COLLECTION,
  PRODUCTS_COLLECTION,
  PRODUCTS_CATEGORIES_COLLECTION,
  SOFTWARE_COLLECTION,
  CASE_STUDIES_COLLECTION,
]
