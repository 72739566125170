import type { CmsCollection, CmsCollectionFile } from 'netlify-cms-core'

import {
  COLLECTION_NAME,
  INTERNAL_PAGES_NAMES,
  PAGE_NAME,
} from '../../../../../config/cms'
import { FOOTER } from './footer'
import { makeContentPath } from './utils'

const HEADER_NAVIGATION: CmsCollectionFile = {
  label: 'Header Navigation',
  name: 'header-navigation',
  i18n: true,
  file: makeContentPath(
    COLLECTION_NAME.CommonElements,
    `header-navigation.json`
  ),
  fields: [
    {
      label: 'Items',
      name: 'items',
      widget: 'list',
      label_singular: 'item',
      i18n: true,
      fields: [
        {
          label: 'Label',
          name: 'label',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Page',
          name: 'page',
          widget: 'select',
          options: [
            PAGE_NAME.About,
            PAGE_NAME.CaseStudies,
            PAGE_NAME.Contact,
            PAGE_NAME.Home,
            PAGE_NAME.Products,
            PAGE_NAME.Software,
          ].map(page => ({
            label: INTERNAL_PAGES_NAMES[page]!,
            value: page,
          })),
        },
      ],
    },
    {
      label: 'Support Button',
      name: 'supportButton',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Label',
          name: 'label',
          widget: 'text',
          i18n: true,
        },
        {
          label: 'Link',
          name: 'link',
          widget: 'text',
          i18n: true,
        },
      ],
    },
  ],
}

const LABELS_AND_INTERNATIONALIZATION: CmsCollectionFile = {
  label: 'Labels and Internationalization',
  name: 'labels-and-internationalization',
  i18n: true,
  file: makeContentPath(
    COLLECTION_NAME.CommonElements,
    `labels-and-internationalization.json`
  ),
  fields: [
    {
      label: 'Strings',
      name: 'strings',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Learn More',
          name: 'learnMoreButton',
          widget: 'string',
          i18n: true,
        },
      ],
    },
  ],
}

export const COMMON_ELEMENTS_COLLECTION: CmsCollection = {
  label: 'Common Elements',
  name: COLLECTION_NAME.CommonElements,
  format: 'json',
  i18n: true,
  files: [HEADER_NAVIGATION, FOOTER, LABELS_AND_INTERNATIONALIZATION],
}
