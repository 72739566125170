export const {
  NODE_ENV = 'development',
  GATSBY_NETLIFY_CMS_BACKEND_NAME = 'gitlab',
  GATSBY_NETLIFY_CMS_BACKEND_BRANCH = 'master',
  GATSBY_NETLIFY_CMS_BACKEND_API_ROOT = 'https://git.elibri.com.pl/api/v4',
  GATSBY_NETLIFY_CMS_BACKEND_REPO_NAME = 'octant/homepage-cms',
  GATSBY_NETLIFY_CMS_BACKEND_AUTH_ENDPOINT = 'oauth/authorize',
  GATSBY_NETLIFY_CMS_BACKEND_AUTH_TYPE = 'pkce',
  GATSBY_NETLIFY_CMS_BACKEND_BASE_URL = 'https://git.elibri.com.pl/',
  GATSBY_NETFLIY_CMS_BACKEND_APP_ID = 'bc43949f9393eb8a5e0c0458be2fc24fd4bc1a370ebe9590bac0ec2d899add0f',
  GATSBY_WEBSITE_HOST = 'homepage-cms.pages.dev',
} = process.env

export const IS_DEVELOPMENT = NODE_ENV === 'development'
