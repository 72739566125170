import type { CmsCollection } from 'netlify-cms-core'

import { COLLECTION_NAME } from '../../../../../config/cms'
import { createIdentifierField, makeContentPath } from './utils'

export const SOFTWARE_COLLECTION: CmsCollection = {
  label: 'Software',
  name: COLLECTION_NAME.Software,
  folder: makeContentPath(COLLECTION_NAME.Software),
  create: true,
  format: 'json',
  i18n: true,
  identifier_field: 'identifier',
  summary: '{{fields.name}}',
  fields: [
    createIdentifierField(),
    { label: 'Name', name: 'name', widget: 'string', i18n: true },
    {
      label: 'Media',
      name: 'media',
      widget: 'object',
      fields: [
        {
          label: 'Preview Image',
          name: 'previewImage',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Preview Image Alt',
          name: 'previewImageAlt',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Content Image',
          name: 'contentImage',
          widget: 'image',
          // @ts-ignore
          choose_url: false,
        },
        {
          label: 'Carousel',
          label_singular: 'item',
          widget: 'list',
          name: 'items',
          fields: [
            {
              label: 'Image',
              name: 'image',
              widget: 'image',
              // @ts-ignore
              choose_url: false,
              required: false,
            },
            {
              label: 'YouTube link',
              name: 'youtube',
              widget: 'string',
              required: false,
            },
          ],
        },
      ],
    },
    { label: 'Summary heading', name: 'heading', widget: 'string', i18n: true },
    {
      label: 'Summary description',
      name: 'description',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Alternative description',
      name: 'altDescription',
      widget: 'text',
      i18n: true,
    },
    {
      label: 'Tags',
      name: 'tags',
      widget: 'relation',
      collection: COLLECTION_NAME.Tags,
      multiple: true,
      search_fields: ['title'],
      value_field: 'identifier',
      display_fields: ['title'],
      required: false,
    },
    {
      label: 'Benefits',
      name: 'benefits',
      widget: 'list',
      i18n: true,
      field: { label: 'Name', name: 'name', widget: 'string', i18n: true },
    },
    {
      label: 'Requirements',
      name: 'requirements',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Hardware requirements',
          name: 'hardwareRequirements',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'Software requirements',
          name: 'softwareRequirements',
          widget: 'list',
          i18n: true,
          field: {
            label: 'Requirement',
            name: 'requirement',
            widget: 'string',
            i18n: true,
          },
        },
      ],
    },
    {
      label: 'Downloadable Files',
      name: 'files',
      widget: 'list',
      i18n: true,
      fields: [
        {
          label: 'Displayed label',
          name: 'displayedLabel',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'File name',
          name: 'fileName',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'File',
          name: 'file',
          widget: 'file',
          i18n: true,
          // @ts-ignore
          choose_url: false,
        },
      ],
    },
    {
      label: 'Features',
      label_singular: 'feature',
      name: 'features',
      widget: 'object',
      i18n: true,
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          widget: 'string',
          i18n: true,
        },
        {
          label: 'List',
          name: 'list',
          widget: 'list',
          i18n: true,
          fields: [
            {
              label: 'Image',
              name: 'image',
              widget: 'image',
              // @ts-ignore
              choose_url: false,
            },
            {
              label: 'Top',
              name: 'top',
              widget: 'string',
              i18n: true,
            },
            {
              label: 'Bottom',
              name: 'bottom',
              widget: 'string',
              i18n: true,
            },
          ],
        },
      ],
    },
  ],
}
